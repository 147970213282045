import React, { FC } from 'react'
import classes from './Agreement.module.css'
import payments from '../../assets/payment.svg'
import styled from 'styled-components'
import { Button } from '../Button'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { Link } from 'react-router-dom'

const DashboardButton = styled(Button)`
  position: absolute;
  top: 25px;
  right: 100px;

  @media ${props => props.theme.media.phone} {
    top: 20px;
    right: 20px;
  }

  @media ${props => props.theme.media.tablet} {
    top: 20px;
    right: 20px;
  }
`

const StyledLogo = styled(Logo)`
  position: absolute;
  top: 25px;
  left: 100px;
  width: 50px;
  height: 50px;
  cursor: pointer;

  @media ${props => props.theme.media.phone} {
    top: 20px;
    left: 20px;
  }

  @media ${props => props.theme.media.tablet} {
    top: 20px;
    left: 20px;
  }
`

export const Agreement: FC = () => {
  return (
    <div className={classes.wrapper}>
      <Link to="/">
        <StyledLogo />
      </Link>

      <DashboardButton href="https://dash.soldoutcook.com">
        DASHBOARD
      </DashboardButton>
      <div className={classes.agreement}>
        <h1>Пользовательское соглашение</h1>
        <p className={classes.important}>
          Настоящее Соглашение определяет условия использования посетителем
          сайта (далее - Пользователь) материалов и сервисов сайта{' '}
          <a href="https://soldoutcook.com" className={classes.pointer}>
            https://soldoutcook.com
          </a>{' '}
          (далее - Сайт), а также регулирует отношения между ИП с основным
          государственным регистрационным номером{' '}
          <a href="#id">322325600000441</a> (далее SoldOut или Администрация) и
          посетителем сайта. Сайт{' '}
          <a href="https://soldoutcook.com" className={classes.pointer}>
            https://soldoutcook.com
          </a>{' '}
          не является средством массовой информации.
        </p>
      </div>

      <div className={classes.agreement}>
        <div className={classes.infoBlock}>
          <h2>1. Общие условия</h2>
          <div className={classes.info}>
            <p>
              1.1. Использование материалов и сервисов Сайта регулируется
              нормами действующего законодательства Российской Федерации.
            </p>
            <p>
              1.2. Настоящее Соглашение является публичной офертой. Получая
              доступ к материалам Сайта, Пользователь считается присоединившимся
              к настоящему Соглашению.
            </p>
            <p>
              1.3. Данное Соглашение вступает в силу при любом использовании
              данного сайта. Соглашение перестает действовать при появлении его
              новой версии. Администрация оставляет за собой право в
              одностороннем порядке изменять данное соглашение по своему
              усмотрению. Администрация не оповещает пользователей об изменении
              в Соглашении.
            </p>
          </div>
        </div>

        <div className={classes.infoBlock}>
          <h2>2. Права и обязанности Пользователя</h2>
          <div className={classes.info}>
            <p>
              2.1. Пользователь соглашается не предпринимать действий, которые
              могут рассматриваться как нарушающие российское законодательство
              или нормы международного права, в том числе в сфере
              интеллектуальной собственности, авторских и/или смежных правах, а
              также любых действий, которые приводят или могут привести к
              нарушению нормальной работы Сайта и сервисов Сайта.
            </p>
            <p>
              2.2. Использование материалов Сайта без согласия Администрации не
              допускается (статья 1270 Г.К РФ).
            </p>
            <p>
              2.3. При цитировании материалов Сайта, включая охраняемые
              авторские произведения, ссылка на Сайт обязательна (подпункт 1
              пункта 1 статьи 1274 Г.К РФ).
            </p>
            <p>
              2.4. Пользователь предупрежден о том, что Администрация Сайта не
              несёт ответственности за посещение и использование им внешних
              ресурсов, ссылки на которые могут содержаться на сайте.
            </p>
            <p>
              2.5. Пользователь согласен с тем, что Администрация Сайта не несёт
              ответственности и не имеет прямых или косвенных обязательств перед
              Пользователем в связи с любыми возможными или возникшими потерями
              или убытками, связанными с любым содержанием Сайта, регистрацией
              авторских прав и сведениями о такой регистрации, товарами или
              услугами, доступными на или полученными через внешние сайты или
              ресурсы либо иные контакты Пользователя, в которые он вступил,
              используя размещенную на Сайте информацию или ссылки на внешние
              ресурсы.
            </p>
            <p>
              2.6. Пользователь принимает положение о том, что все материалы и
              сервисы Сайта или любая их часть могут сопровождаться рекламой.
              Пользователь согласен с тем, что Администрация Сайта не несёт
              какой-либо ответственности и не имеет каких-либо обязательств в
              связи с такой рекламой.
            </p>
            <p>
              2.7. Пользователь согласен с тем, что он имеет право осуществлять
              только поиск информации на сайте и использовать её в личных
              некоммерческих целях.
            </p>
            <p>
              2.8. В случае нарушения пользователем правил из канала #rules в
              Discord сервере, администрация сервера оставляет за собой право
              удалить ключ пользователя и ограничить доступ пользователя к
              серверу без возврата денежных средств.
            </p>
          </div>
        </div>

        <div className={classes.infoBlock}>
          <h2>3. Права и обязанности Администрации</h2>
          <div className={classes.info}>
            <p>
              3.1. Администрация Сайта вправе в любое время в одностороннем
              порядке изменять условия настоящего Соглашения. Такие изменения
              вступают в силу по истечении 3 (трёх) дней с момента размещения
              новой версии Соглашения на сайте.
            </p>
            <p>
              3.2. Администрация Сайта вправе создавать, изменять и удалять
              информацию на Сайте, а также ограничивать доступ к информации.
            </p>
            <p>
              3.3. Администрация обязуется поддерживать работоспособность сайта,
              за исключением случаев, когда это невозможно по независящим от
              Администрации причинам.
            </p>
            <p>
              3.4. Администрация обязуется осуществлять разностороннюю защиту
              учётной записи пользователя.
            </p>
            <p>
              3.5. Администрация обязуется предоставить всю доступную информацию
              о Пользователе уполномоченным на то органам государственной власти
              в случаях, установленных законом.
            </p>
          </div>
        </div>

        <div className={classes.infoBlock}>
          <h2>4. Ответственность сторон</h2>
          <div className={classes.info}>
            <p>
              4.1. Администрация не несёт никакой ответственности за услуги,
              предоставляемые третьими лицами.
            </p>
            <p>
              4.2. В случае возникновения форс-мажорной ситуации (боевые
              действия, чрезвычайное положение, стихийное бедствие и т. д.)
              Администрация не гарантирует сохранность информации, размещённой
              Пользователем, а также бесперебойную работу информационного
              ресурса.
            </p>
            <p>
              4.3. Администрация принимает платёжные данные Пользователя и
              перенаправляет их в Тинькофф. За обработку и сохранность
              персональных банковских данных Пользователя отвечает Тинькофф (АО
              «Тинькофф Банк»).
            </p>
          </div>
        </div>

        <div className={classes.infoBlock}>
          <h2>5. Процесс покупки товаров на сайте</h2>
          <div className={classes.info}>
            <p>
              5.1. Сайт предоставляет продукт – закрытый доступ к Discord
              серверу. Данный доступ предоставляется Пользователю, который
              оплатил месячную подписку.
            </p>
            <p>
              5.2. Покупку доступа к частному серверу Discord можно осуществить
              на сайте https://soldoutcook.com с помощью платежного метода -
              банковская карта. Когда пользователь выберет способ оплаты, виджет
              сообщит, что способ оплаты будет привязан к магазину. При успешной
              оплате данные карты или кошелька автоматически сохранятся в
              Тинькофф и на Сайте.
            </p>
            <p>
              5.3. Чтобы совершить покупку, необходимо нажать на кнопку «Buy», в
              результате чего появится окно, в которое нужно будет ввести
              пароль. Пароль предоставляется администрацией.
            </p>
            <p>
              5.4. Когда вы совершите покупку, в вашем браузере откроется
              dashboard с уже привязанным уникальным ключом. Нажав на кнопку
              «Discord server», вы окажетесь в закрытом сервере.
            </p>
            <p>
              5.5. Дальнейшая (1 раз в месяц) оплата производится автоматически.
              Деньги списываются с указанной в персональных данных карты каждые
              30 (тридцать), сумма списания составляет 1500 (тысяча пятьсот), 2000 (две тысячи) или 3000
              (три тысячи) рублей, в зависимости от даты приобретения ключа.
            </p>
            <p>
              5.6. SoldOut оставляет за собой право в принятии решения о
              возврате или невозврате денежных средств. Возврат денежных средств
              осуществляется только в том случае, если товар распродан, и при
              этом была проведена лишняя оплата.
            </p>
            <p>
              5.7. Пользователь в праве отказаться от продления подписки,
              отменив её через личный кабинет. В таком случае, новых снятий с
              карты Пользователя не будет, а деньги, снятые за последний месяц
              пользования, не будут возращены на карту.
            </p>
          </div>
        </div>

        <div className={classes.infoBlock}>
          <h2>6. Прочие условия</h2>
          <div className={classes.info}>
            <p>
              6.1. Все возможные споры, вытекающие из настоящего Соглашения или
              связанные с ним, подлежат разрешению в соответствии с действующим
              законодательством Российской Федерации.
            </p>
            <p>
              6.2. Ничто в Соглашении не может пониматься как установление между
              Пользователем и Администрацией Сайта агентских отношений,
              отношений товарищества, отношений по совместной деятельности,
              отношений личного найма, либо каких-то иных отношений, прямо не
              предусмотренных Соглашением.
            </p>
            <p>
              6.3. Признание судом какого-либо положения Соглашения
              недействительным или не подлежащим принудительному исполнению не
              влечет недействительности иных положений Соглашения.
            </p>
            <p>
              6.4. Бездействие со стороны Администрации Сайта в случае нарушения
              кем-либо из Пользователей положений Соглашения не лишает
              Администрацию Сайта права предпринять позднее соответствующие
              действия в защиту своих интересов и защиту авторских прав на
              охраняемые в соответствии с законодательством материалы Сайта.
            </p>
            <p>
              6.5. Пользователь подтверждает, что ознакомлен со всеми пунктами
              настоящего Соглашения и безусловно принимает их.
            </p>
          </div>
        </div>

        <div className={classes.infoBlock}>
          <h2>7. Реквизиты</h2>
          <div className={classes.info}>
            <p>ИП Винокуров Максим Сергеевич</p>
            <p>ОГРНИП 322325600000441</p>
            <p>ИНН 325003767735</p>
          </div>
        </div>

        <div className={classes.infoBlock}>
          <img
            src={payments}
            alt="Tinkoff Payment (Visa, Mastercard, Maestro, MIR)"
          />
        </div>
      </div>

      <div className={classes.agreement}>
        <h1>Оферта регулярных платежей</h1>

        <p className={classes.important}>
          СОГЛАШЕНИЕ О ПРЕДОСТАВЛЕНИИ ФИЗИЧЕСКИМ ЛИЦАМ ВОЗМОЖНОСТИ ОПЛАТЫ
          ТОВАРОВ/РАБОТ/УСЛУГ ИП Винокуров Максим Сергеевич ПОСРЕДСТВОМ
          РЕГУЛЯРНЫХ АВТОМАТИЧЕСКИХ ПЕРЕВОДОВ ДЕНЕЖНЫХ СРЕДСТВ.
        </p>

        <p className={classes.important}>
          ИП Винокуров Максим Сергеевич зарегистрированный по адресу г.Брянск,
          улица Любезного д 2, действующий на основании свидетельства
          322325600000441, именуемый в дальнейшем Поставщик, предлагает
          пользователям воспользоваться Сервисом «Рекуррентные списания» и
          заключить настоящее соглашение (далее — Соглашение), являющееся
          дополнением к Договору и неотъемлемой частью Договора, об определении
          порядка расчетов по Договору. Пользователь безусловно соглашается с
          настоящим Соглашением пользования сервисом путём совершения
          конклюдентных действий по подключению Сервиса «Рекуррентные списания»
          в момент принятия Пользователем настоящего соглашения пользования
          сервисом и выполнения действий по активации Сервиса «Рекуррентные
          списания».
        </p>

        <div className={classes.content}>
          <div className={classes.infoBlock}>
            <h2>Термины и определения</h2>
            <div className={classes.info}>
              <p>
                {' '}
                <span className={classes.name}>
                  Сервис «Рекуррентные списания»
                </span>{' '}
                — сервис по осуществлению регулярных автоматических переводов
                денежных средств с Банковской карты Держателя в пользу
                Поставщика за предоставляемые Поставщиком товары/услуги по
                Договору на основании заранее данного акцепта Держателя карты, в
                соответствии с тарифами и опциями по Договору.
              </p>
              <p>
                {' '}
                <span className={classes.name}>Договор</span> - двустороннее
                соглашение, заключенное между Пользователем и Поставщиком по
                условиям которого, Поставщик обязуется предоставить Пользователю
                Услугу, а последний обязуется оплатить их.
              </p>
              <p>
                {' '}
                <span className={classes.name}>Услуга/Товар</span> -
                предоставление Поставщиком услуги/товара по из ассортимента
                сайта{' '}
                <a href="https://soldoutcook.com">https://soldoutcook.com</a>
              </p>
              <p>
                {' '}
                <span className={classes.name}>Пользователь</span> — физическое
                лицо, заключившее Договор с Поставщиком.
              </p>
              <p>
                {' '}
                <span className={classes.name}>Банк</span> – кредитная
                организация — АО «Тинькофф Банк» БИК:044525974
                К/с:30101810145250000974
              </p>
              <p>
                {' '}
                <span className={classes.name}>Запрос</span> – информационное
                сообщение, направляемое Банком в Банк-эмитент на перевод
                денежных средств с Банковской карты в пользу Поставщика.
              </p>
              <p>
                {' '}
                <span className={classes.name}>Банк-эмитент</span> — кредитная
                организация, осуществляющая выпуск Банковских карт, а также
                расчеты по операциям, совершаемым с использованием Банковских
                карт.
              </p>
              <p>
                {' '}
                <span className={classes.name}>Процессинговый центр</span> — АО
                «Тинькофф Банк» БИК:044525974 К/с:30101810145250000974
              </p>
              <p>
                {' '}
                <span className={classes.name}>Держатель карты</span> –
                физическое лицо, дающее распоряжение Банку-эмитенту на перевод
                денежных средств с Банковской карты от имени Пользователя в
                пользу Поставщика с помощью Сервиса «Рекуррентные списания».
              </p>
              <p>
                {' '}
                <span className={classes.name}>Банковская карта</span> —
                расчетная или кредитная карта, эмитентом которой является
                Банк-эмитент, являющаяся инструментом безналичных расчетов,
                предназначенная для совершения Держателем карты операций с
                денежными средствами, находящимися на банковских счетах
                Держателя карты в Банке-эмитенте, или с денежными средствами,
                предоставленными Банком-эмитентом в кредит Держателю карты в
                соответствии с законодательством Российской Федерации, а также
                договором банковского счета, или в пределах установленного
                лимита, в соответствии с условиями кредитного договора между
                Банком-эмитентом и Держателем карты, при условии, что такая
                расчетная или кредитная карта может быть использована Держателем
                карты для оплаты Услуг Поставщика. Оплата Услуг Поставщика в
                соответствии с настоящим Соглашением возможна Банковскими
                картами платежных систем VISA International, MasterCard
                International, зарегистрированными в соответствии с действующим
                законодательством Российской Федерации. При этом возможность
                использования конкретных видов карт VISA International и
                MasterCard International в устанавливает Банк-эмитент.
              </p>
              <p>
                {' '}
                <span className={classes.name}>Акцепт Пользователя</span> –
                безусловное согласие Пользователя с условиями настоящего
                Соглашения.
              </p>
              <p>
                {' '}
                <span className={classes.name}>Личный кабинет</span> – раздел
                Сайта Поставщика, защищенный системой контроля доступа,
                обеспечивающий Пользователю интерфейс для взаимодействия с
                Поставщиком по Договору.
              </p>
              <p>
                {' '}
                <span className={classes.name}>Сайт Поставщика</span> - сайт,
                размещенный в сети Интернет по адресу:{' '}
                <a href="https://soldoutcook.com">https://soldoutcook.com</a>{' '}
              </p>
            </div>
          </div>
          <div className={classes.infoBlock}>
            <h2>
              1. Описание сервиса "Рекурентные списания" и способ его активации
            </h2>
            <div className={classes.info}>
              <p>
                1.1. Сервис «Рекуррентные списания» позволяет Пользователю
                оплачивать Услуги Поставщика по Договору, путём регулярных
                автоматических переводов денежных средств в пользу Поставщика с
                Банковских карт. Активация (подключение) Сервиса «Рекуррентные
                списания» предполагает выполнение следующих действий:
              </p>
              <p className={classes.point}>
                1.1.1 На странице покупки товара Сайта Поставщика, Пользователь
                вводит e-mail почту и нажимает кнопку “Purchase”. После оплаты
                выбранного Пользователем товара, подключается сервис
                “Рекуррентные списания”.
              </p>
              <p className={classes.point}>
                1.1.2 В личном кабинете Сайта Поставщика, Пользователь нажимает
                кнопку “Activate” в разделе “Auto-payment”. После чего
                Пользователь оплачивает минимальную сумму для подключения
                Сервиса “Рекуррентные списания”. Средства сразу же возвращаются
                на Банковскую карту Пользователя, но с Банковской карты будут
                совершаться Рекуррентные списания.
              </p>
            </div>
          </div>

          <div className={classes.infoBlock}>
            <h2>2. Действие cервиса «Рекуррентные списания»</h2>
            <div className={classes.info}>
              <p>
                2.1. После подключения Сервиса «Рекуррентные списания», с
                Банковской карты будут осуществляться переводы денежных средств
                в сроки и размерах, необходимых для надлежащего исполнения
                обязанности Пользователя по Договору.
              </p>
              <p>
                2.2. Непосредственное списание денежных средств осуществляет
                Банк-эмитент на основании заранее данного согласия Пользователя
                на подобное списание в сумме и в срок, указанные в Договоре.
                Запрос на перевод денежных средств с указанием суммы перевода в
                Банк-эмитент передается Банком и Процессинговым центром в рамках
                заключенных с Поставщиком соглашений.
              </p>
              <p>
                2.3. В один момент времени, у Пользователя может быть
                активирован (подключён) Сервис «Рекуррентные списания» только по
                одной Банковской карте платёжной системы Visa (Visa Inc.),
                MasterCard или Maestro (MasterCard International Inc.).
              </p>
              <p>
                2.4. Поставщик не хранит и не обрабатывает данные банковских
                карт Пользователей, а также иные персональные данные
                Пользователей, обеспечивая лишь запросы в Процессинговый центр и
                Банк для повторного проведения операции по банковской карте
                Пользователя.
              </p>
              <p>
                2.5. Поставщик не гарантирует возможность проведения операций по
                Банковской карте.
              </p>
              <p>
                2.6. Пользователь гарантирует, что он является Держателем
                банковской карты, осознанно, корректно и полностью вводит все
                требуемые реквизиты Банковской карты при активации (подключении)
                Сервиса «Рекуррентные списания».
              </p>
              <p>
                2.7. Совершение действий, предусмотренных п. п.1.1. и п.п.1.2.
                Соглашения признается аналогом собственноручной подписи
                Пользователя.
              </p>
              <p>
                2.8. Подключение Сервиса «Рекуррентные списания», в порядке,
                предусмотренном настоящим Соглашением пользования сервисом,
                осуществляется только при наличии технической возможности
                Поставщика, Процессингового центра, Банка,
                Банка-эмитента.Поставщик, Процессинговый центр, Банк не несут
                ответственность за невозможность подключения Сервиса
                «Рекуррентные списания».
              </p>
              <p>
                2.9. Поставщик имеет право вносить изменения в настоящее
                Соглашение пользования сервиса, заранее уведомив об этом
                Пользователя в письменной форме либо иным доступным способом, в
                том числе путем сообщения на электронную почту, указанную в
                Договоре Пользователем, либо телефонограммой, либо размещением
                информации на Веб-Сайте Поставщика.
              </p>
              <p>
                2.10. Денежные средства списываются с Банковской карты
                Пользователя один раз в календарный месяц.
              </p>
              <p>
                2.11. Дату следующего списания Пользователь может просматривать
                в личном кабинете Сайта Поставщика
              </p>
            </div>
          </div>

          <div className={classes.infoBlock}>
            <h2>3. Отключение сервиса «Рекуррентные списания»</h2>
            <div className={classes.info}>
              <p>
                3.1. Отключение (отказ) Пользователя от Сервиса «Рекуррентные
                списания» происходит:{' '}
              </p>
              <p className={classes.point}>
                A) В «Личном кабинете» Пользователь нажимает кнопку “Cancel” в
                разделе “Auto-payment”. При этом денежные средства переведенные
                с Банковской карты до отключения (отказа)Пользователя от Сервиса
                «Рекуррентные списания» за Услуги, Пользователю не возвращаются.
              </p>
              <p>
                3.2. В случае недостаточности денежных средств на счете
                Банковской карты для осуществления перевода Поставщик имеет
                право повторять запрос на списание с Банковской карты денежных
                средств.
              </p>
            </div>
          </div>

          <div className={classes.infoBlock}>
            <h2>
              4. Ограничения при пользовании сервисом «Рекуррентные списания»
            </h2>
            <div className={classes.info}>
              <p>
                4.1. С одной Банковской карты может быть проведено не более 5
                (пяти) переводов в сутки;
              </p>
              <p>
                4.2. С одной Банковской карты может быть осуществлен перевод не
                более, чем на 15000 рублей за одну операцию;
              </p>
              <p>
                4.3. С одной Банковской карты может быть осуществлено переводов
                не более, чем на 50000 рублей в сутки;
              </p>
              <p>
                4.4. Переход на страницу «Сделать платеж» возможен, лишь в
                случае если у Пользователя подключено соединение к сети
                Интернет, независимо от текущего состояния счёта.
              </p>
            </div>
          </div>

          <div className={classes.infoBlock}>
            <h2>5. Ответственность сторон</h2>
            <div className={classes.info}>
              <p>
                5.1. Поставщик, Процессинговый центр, Банк не несёт
                ответственности за неработоспособность и/или временную
                неработоспособность Сервиса «Рекуррентные списания».
                Пользователь самостоятельно контролирует исполнение своей
                обязанности по оплате по Договору. При неработоспособности и/или
                временной неработоспособности Сервиса «Рекуррентные списания»
                Пользователь обязан использовать иные способы исполнения
                обязанностей по Договору.
              </p>
              <p>
                5.2. Споры сторон, возникшие в связи с выполнением условий
                настоящего Соглашения, разрешаются в ходе направления претензий.
              </p>
              <p>
                5.3. В случае недостижения Сторонами взаимного согласия, споры
                между ними разрешаются в соответствии с условиями Договора и
                Оферты.
              </p>
            </div>
          </div>

          <div className={classes.infoBlock}>
            <h2>6. Прочие условия</h2>
            <div className={classes.info}>
              <p>
                6.1. Выполняя действия по подключению Сервиса «Рекуррентные
                списания»:
              </p>
              <p className={classes.point}>
                А) Пользователь безусловно соглашается со всеми условиями
                настоящего Соглашения.
              </p>
              <p className={classes.point}>
                Б) Пользователь дает заранее данный акцепт на списание денежных
                средств Поставщиком с Банковской карты на условиях настоящего
                Соглашения.
              </p>
              <p className={classes.point}>
                В) Пользователь предоставляет право Процессинговому Центру,
                Банку хранить, собирать, обрабатывать свои персональные данные
                (Фамилию, Имя, Отчество, серию и номер паспорта, место и дату
                рождения, номер расчетного счета и Банковской карты) с целью
                реализации настоящего Соглашения.
              </p>
              <p className={classes.point}>
                Г) Пользователь дает согласие на оплату Услуг в соответствии с
                условиями Соглашения
              </p>
              <p>
                6.2. Поставщик имеет право отключить и/или ограничить
                Пользователя от Сервиса «Рекуррентные списания»
              </p>
            </div>
          </div>

          <div className={classes.infoBlock}>
            <h1>«Рекуррентные списания»</h1>
            <p className={classes.important}>
              ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ И Поставщика, ВЫТЕКАЮЩИЕ ИЗ
              НАСТОЯЩЕЙ ОФЕРТЫ, НЕ МОГУТ БЫТЬ ПЕРЕДАНЫ ТРЕТЬИМ ЛИЦАМ БЕЗ
              ПИСЬМЕННОГО СОГЛАСИЯ ДРУГОЙ СТОРОНЫ. Я, ПОЛЬЗОВАТЕЛЬ, ОЗНАКОМЛЕН И
              СОГЛАСЕН С НАСТОЯЩИМ СОГЛАШЕНИЕМ ИСПОЛЬЗОВАНИЯ СЕРВИСА
              «РЕКУРРЕНТНЫЕ ПЛАТЕЖИ», С АВТОМАТИЧЕСКИМ СПИСАНИЕМ ДЕНЕЖНЫХ
              СРЕДСТВ С БАНКОВСКОЙ КАРТЫ ЗА УСЛУГИ ПОСТАВЩИКА ПО ДОГОВОРУ.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
